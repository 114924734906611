




import { Component, Vue } from 'vue-property-decorator'

@Component
export default class StudentOpenPdf extends Vue {
  private tokenParam = this.$route.query.token

  private created() {
    const isSozo = Vue.prototype.$gdlsCookiesV3?.isSozo()
    if (!isSozo) {
      return this.$router.push('/student/sozo/select-subject')
    }
    if (isSozo) {
      const params = this.$route.params
      const url = `${params.type}/${params.subjectCode}/${params.grade}/${params.kind}`
      sessionStorage.setItem('url', url)
      return
    }
  }
}
